<div class="info">

  <h2>Deine Route wurde erfolgreich exportiert!</h2>
  <p>Daten werden heruntergeladen.</p>

  <p class="old_state"><b>Export Log:</b></p>
  <p *ngFor="let old_state of statusManager.history" class="old_state">
    &nbsp;- <b>{{old_state?.last_change}}:</b> {{old_state?.message}}
  </p>
  <p class="old_state" [ngClass]="{'error_message': statusManager.status == 'Fehler'}">
    &nbsp;- <b>{{statusManager.last_change}}:</b> {{statusManager.status_message}} <i> ({{statusManager.status}})</i>
  </p>

  <p><br></p>

  <p>Falls das Herunterladen nicht automatisch startet, klicke auf den folgenden Button. Deine Daten sind
    bis {{available_until}} verfügbar.</p>

  <a mat-stroked-button href="{{download_link}}"> Daten herunterladen</a>

  <p><br></p>

  <button mat-stroked-button routerLink="/"> Weitere Route Exportieren</button>

</div>
